.no-padding{
    padding: 0;
}

.blue-button{
    background-color: #2AA4FF;
    border-radius: 30px;
    padding: 10px;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
}

.setting-button{
    background-color: #FFFFFF;
    border-radius: 30px;
    padding: 10px 15px 10px 15px;
    text-align: center;
    color: #53C4FF;
    border: solid 1px #53C4FF;
    cursor: pointer;
}

.input-product-container{
    width: 600px;
}