.no-padding{
    padding: 0;
}

.rounded-border{
    border-radius: 10px;
}

.solid-border{
    border: solid 2px #F4F4F4;
}

.top-resume-item{
    border-right: solid 2px #F4F4F4;

}