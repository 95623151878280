.login-container{
    margin-top: 20vh;
}
.btn-login{
    border-radius: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.input-textfield{
    width: 100%;
    border: solid 1px #53C4FF;
}