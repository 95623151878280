.no-padding{
    padding: 0;
}

.filter-container{
    background-color: #53B5FF;
    border-radius: 30px;
    /* padding: 3px; */
}

.filter-item-active{
    background-color: #2AA4FF;
    border-radius: 30px;
    padding: 5px 10px 5px 10px;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
}

.filter-item{
    background-color: #53B5FF;
    border-radius: 30px;
    padding: 5px 10px 5px 10px;
    text-align: center;
    font-size: 14px;
}