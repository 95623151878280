.no-padding{
    padding: 0;
}

.filter-container{
    background-color: #53B5FF;
    border-radius: 30px;
    padding: 3px;
}

.filter-item-active{
    background-color: #2AA4FF;
    border-radius: 30px;
    padding: 5px 10px 5px 10px;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

}

.filter-item{
    background-color: #53B5FF;
    border-radius: 30px;
    padding: 5px 10px 5px 10px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}

.info{
    width: 500px;
    padding: 10px;
}

.avatar{
    width: 150px;
    height: 150px;
    border-radius: 1000px;
}

.row-padding{
    padding: 10px;
}

.btn-rounded{
    background-color: #53B5FF;
    border-radius: 30px;
    padding: 5px 10px 5px 10px;
    text-align: center;
    font-size: 14px;
}